<template>
  <v-modal
    ref="modal"
    v-model="isVisible"
    size="w-[85rem]"
  >
    <template #header> {{ props.agreement.name }} </template>

    <p class="mb-2.5">
      <nuxt-link
        :to="props.agreement.uri"
        target="_blank"
      >
        <Icon
          name="tabler:external-link"
          class="relative -top-[1px]"
        />

        View PDF
      </nuxt-link>
    </p>

    <iframe
      :src="props.agreement.uri"
      class="mb-5 aspect-[9/16] max-h-[calc(100vh-20rem)] w-full"
    />

    <form class="grid grid-cols-3 gap-5">
      <v-label label="Name">
        <v-input v-model="form.signatory_name" />
      </v-label>

      <v-label label="6 digit code from your authenticator app">
        <v-input v-model="form['2fa_code']" />
      </v-label>

      <v-label label="Date">
        <v-input
          :model-value="new Date().toLocaleDateString()"
          disabled
        />
      </v-label>
    </form>

    <template #footer>
      <v-button
        variant="primary"
        :loading="isSubmitting"
        @click="submit"
      >
        Sign
      </v-button>
    </template>
  </v-modal>
</template>

<script setup lang="ts">
import { VModal } from "#components"
import type { Agreement, Payload } from "core/types/api"

const props = defineProps<{
  agreement: Agreement
}>()

const modal = ref<InstanceType<typeof VModal>>()
const isVisible = ref(false)

defineExpose({
  show: () => modal.value?.show(),
  hide: () => modal.value?.hide(),
})

const form = reactive<Payload<typeof signAgreement>>({
  id: props.agreement.id,
  signatory_name: "",
  "2fa_code": "",
})
watchEffect(() => {
  form.id = props.agreement.id
})
watchEffect(() => {
  if (!isVisible.value) {
    form.signatory_name = ""
    form["2fa_code"] = ""
  }
})

const agreementsStore = useAgreementsStore()

const isSubmitting = ref(false)
async function submit() {
  if (isSubmitting.value) {
    return
  }

  isSubmitting.value = true
  try {
    await signAgreement(form)
    await agreementsStore.load()

    isVisible.value = false
    form.signatory_name = ""
    form["2fa_code"] = ""

    const { notifySuccess } = useNotification()
    notifySuccess("Agreement was signed")

    const emitter = useEmitter()
    emitter.emit("agreementSigned", props.agreement)
  } finally {
    isSubmitting.value = false
  }
}
</script>
